<script lang="tsx">
import { defineComponentCoreIcon } from '@core/app/components/core/icon/CoreIcon.vue'

export default defineComponentCoreIcon(
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 20">
        <path fill="currentColor"
            fill-rule="evenodd"
            d="M1.542 5c0-.805.653-1.458 1.458-1.458h15c.805 0 1.458.653 1.458 1.458v10.833c0 .806-.653 1.459-1.458 1.459H3a1.458 1.458 0 0 1-1.458-1.459V5ZM3 4.792A.208.208 0 0 0 2.792 5v10.833c0 .115.093.209.208.209h15a.208.208 0 0 0 .208-.209V5A.208.208 0 0 0 18 4.792H3Z"
            clip-rule="evenodd"
        />
        <path fill="currentColor"
            fill-rule="evenodd"
            d="m19.224 4.655-7.83 6.263a1.458 1.458 0 0 1-1.785.028L1.792 5.083l.75-1 7.816 5.863a.208.208 0 0 0 .256-.004l7.829-6.263.78.976Z"
            clip-rule="evenodd"
        />
    </svg>
)

</script>

<style lang="scss" scoped>

</style>
